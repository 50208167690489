import moment from 'moment';
import {
  observable, computedFrom, bindable, inject
} from 'aurelia-framework';

import * as Actions from './components/cctv-module-store-manager/actions.js';

import { Store } from 'aurelia-store';

import {
  AdvancedSettingsDialog
} from './components/advanced-settings-dialog/advanced-settings-dialog';
import {
  DialogService
} from 'aurelia-dialog';

import alertsService from 'services/api/alertsService';
import telemetryService from 'services/api/telemetryService';
import cctvService from 'services/api/cctvService';
import { TranslationService } from 'services/translationService';

// import video from 'video.js';
// import { DateTimeUtils } from '@fonix/web-utils';

import JMuxer from 'jmuxer';
import * as signalR from '@microsoft/signalr';
import { MessagePackHubProtocol } from '@microsoft/signalr-protocol-msgpack';

import {
  ShareDownloadDialog
} from './components/share-download-dialog/share-download-dialog';
PLATFORM.moduleName('./components/share-download-dialog/share-download-dialog');
import './cctv.scss';

export class Cctv {
  @observable selectedChannels;
  @observable rangedTime;
  @observable errorMessage;
  @observable toggleLeftContent;
  @bindable checkboxChange;

  static inject = [Store, TranslationService, DialogService];
  constructor(store, _TranslationService, dialogService) {
    this.translations = _TranslationService;

    this.dialogService = dialogService;
    this.alertsService = alertsService;
    this.telemetryService = telemetryService;
    this.cctvService = cctvService;
    this.unbindComponent = false;

    this.loading = false;
    this.filters = {
      dates: null,
      asset: null
    }

    this.snapshot = false;
    this.toggleLeftContent = true;
    this.toggleStopBtn = false;
    this.content = {};
    this.contentMedia = {};
    this.selectedChannels = [];
    this.playingSettingsMedia = [];
    this.downloadsSize = null;

    this.prompt = true;
    this.errorMessage = {
      message: '',
      button: {
        label: '',
        action: ''
      },
      mediaSettings: null
    };

    this.customDates = {
      dtfrom: null,
      dtto: null
    };

    this.paused = false;
    this.loadingMedia = false;
    this.playing = false;
    this.mute = true;
    this.download = false;
    this.maxDownloadRangeDuration = 30;
    this.timelineDownloadRange = {
      startDateTime: null,
      endDateTime: null
    }
    this.id;
    this.checkMedia = false;
    this.checkingDownloads = false;
    this.stopPoll = false;
    this.videoTime = 0;
    this.screenIsBig = true;

    this.deviceId = null;
    this.deviceNames = '';
    this.store = store;
    this.actions = Actions;
    this.videoChannelHls = null;
    this.availableDownloadList = [];

    this.onFilterChanged = this.onFilterChanged.bind(this);
    this.onSnackbarBtnClick = this.onSnackbarBtnClick.bind(this);

    this.deviceOfflineLabel = this.translations.getCap('download_start_on_device_online');

    this.player = null;
  }

  attached() {
    this.store.state.subscribe((state) => {
      if (state.getReloadDownloads) {
        this.loadCctvDownloadHistory(true)
      } else if (state.getGlobalMessage) {
        this.errorMessage.message = state.getGlobalMessage.message;
        this.errorMessage.button.label = state.getGlobalMessage.button.label;
        this.errorMessage.button.action = state.getGlobalMessage.button.action;
        !state.getGlobalMessage.prompt ? this.prompt = false : this.prompt = true;
        state.getGlobalMessage.mediaSettings ? this.errorMessage.mediaSettings = state.getGlobalMessage.mediaSettings : '';
      }
    });

    this.prompt = false;
    this.errorMessage.message = '';
    this.errorMessage.button.label = '';
    this.errorMessage.button.action = '';
  }

  onSnackbarBtnClick(action) {
    try {
      switch (action) {
        case 'data':
          this.loadCctvContent();
          break;

        default:
          if (this.errorMessage.mediaSettings) {

            function asyncFunction(item, cb, filter) {
              setTimeout(() => {
                cctvService.deleteMediaLease(item.device, item.channel).then(res => {
                  if (res.mediaId) {
                    cctvService.stopMedia(res.mediaId, item.channel, filter, 'download')
                      .then(r => {
                        cctvService.deleteDownload(res.mediaId).then(() => {
                          cb();
                        })
                      })

                  } else if (res.streamId) {
                    cctvService.stopLiveStreaming(res.streamId, filter, item.channel)
                      .then(() => {
                        cb();
                      });
                  }
                }).catch(() => {
                  cb();
                })
              }, 100);
            }

            let requests = this.errorMessage.mediaSettings.reduce((promiseChain, item) => {
              return promiseChain.then(() => new Promise((resolve) => {
                asyncFunction(item, resolve, this.filters.asset.deviceUUId);
              }));
            }, Promise.resolve());

            requests.then(() => {
              if (action === 'download') {
                this.errorMessage.mediaSettings.forEach(i => {
                  this.cctvService.getDeviceDownload(i).then(() => {
                    this.loadCctvDownloadHistory(true);
                  })
                })
              } else if (action === 'stream') {
                this.startMedia();
              }

            }).catch(e => {
              this.prompt = false;
              this.errorMessage.message = 'Channel error, please try again later';
              this.errorMessage.button.label = '';
              this.errorMessage.button.action = '';
            })
          }
      }
    } catch (e) {
      console.log('error', e)
    }
  }

  toggleLeftContentChanged() {
    if (document.getElementById('leftPanel')) {
      let leftContent = document.getElementById('leftPanel')
      if (!this.toggleLeftContent) {
        leftContent.style.display = 'none';
      } else {
        setTimeout(() => {
          leftContent.style.display = 'block';
        }, 300);
      }
    }
  }

  rangedTimeChanged() {
    this.maxDownloadRangeDuration = this.rangedTime;
  }

  getDate(start, end) {
    let totalSeconds = moment(end).diff(moment(start), 'seconds');
    let output = moment(start).format('YYYY-MM-DD HH:mm:ss') + ' (' + totalSeconds + 's)';
    return output
  }

  getDownload(id) {
    let link = this.cctvService.encodeMediaIdWithToken(id, 'media')
    window.open(link, "_self");
  }

  onStopDownload(mediaId, mediaChannel) {
    return this.cctvService.stopMedia(mediaId, mediaChannel, this.filters.asset.deviceUUId, 'download')
      .then(() => {
        this.cctvService.deleteDownload(mediaId).then(r => {
          this.loadCctvDownloadHistory(true);
        })
      })
      .catch(() => {
        this.loading = false;
      });
  }

  checkLabel(channel) {
    let device = null;
    if (this.deviceNames) {
      device = this.deviceNames.channels.find(i => channel == i.id);
      if (device && device.name && device.name !== '') {
        return device.name
      }
    }

    return this.translations.getCap('channel ') + channel
  }

  onFilterChanged(filters = {}) {
    if (this.playing) {
      this.pauseMedia(true);
    }

    this.content = {};
    this.contentMedia = {};
    this.availableDownloadList = [];
    this.selectedChannels = [];
    this.download = false;
    this.timelineDownloadRange = {
      startDateTime: null,
      endDateTime: null
    }


    if (typeof filters.asset !== 'undefined') {
      this.filters.asset = filters.asset
    }

    if (!filters.asset) {
      this.filters.asset = null;
    }

    if (filters.dates) {
      this.filters.dates = filters.dates;

      if (this.filters.dates.dtfrom && this.filters.dates.dtto) {
        this.timelineCurrentDate = this.filters.dates.dtfrom;
      }
    }

    if (this.filters.dates.dtfrom && this.filters.dates.dtto && this.filters.asset && this.filters.asset.id) {
      this.snapshot = this.filters.asset.isDeviceConnected;
      this.checkingDownloads = false;
      this.stopPoll = false;
      this.loadCctvDownloadHistory();
      this.cctvService.getDevice(this.filters.asset.deviceId).then(device => {
        if (device.hasOwnProperty('metadata')) {
          this.deviceNames = device.metadata;
        }
      })
      if (this.filters.asset.isDeviceConnected) {
        this.loadCctvContent();
      }

    } else {
      this.stopPoll = true;
    }
  }

  // schedule download
  showDownloadDialog() {
    var date = new Date();
    const timelineDownloadRange = {
      startDateTime: moment(date).subtract(20, 'seconds'),
      endDateTime: moment(date),
      rangedTime: 20
    };

    this.dialogService
      .open({
        viewModel: AdvancedSettingsDialog,
        model: {
          timelineDownloadRange: timelineDownloadRange,
          timelineBorders: this.filters.dates,
          channels: this.deviceNames.channels,
          isOfflineDownload: true,
        }
      })
      .whenClosed(result => {
        if (!result.wasCancelled) {
          const selectedChannels = result.output.channels;
          selectedChannels.forEach(element => {
            const payload = {
              channel: element.id,
              device: this.filters.asset.deviceUUId,
              startDateTime: moment(result.output.startDateTime).utc(),
              endDateTime: moment(result.output.endDateTime).utc()
            };
            this.cctvService.getDeviceDownload(payload).then(() => this.loadCctvDownloadHistory(true));
          })

        }
      });
  }

  loadCctvDownloadHistory(data) {
    !data ? this.checkingDownloads = false : '';
    if (!this.checkingDownloads) {
      this.checkingDownloads = true;
      const poll = async ({ fn, validate, interval, maxAttempts }) => {
        let attempts = 0;

        const executePoll = async (resolve, reject) => {
          const result = await fn();
          attempts++;

          if (validate(result)) {
            this.availableDownloadList = result;
            this.checkingDownloads = false;
            return resolve(result);
          } else if (maxAttempts && attempts === maxAttempts) {
            this.checkingDownloads = false;
            // return reject(new Error('Exceeded max attempts'));
          } else {
            if (result !== 204 && !this.unbindComponent) {
              this.availableDownloadList = result;
              setTimeout(executePoll, interval, resolve, reject);
            } else {
              this.availableDownloadList = [];
              this.checkingDownloads = false;
            }
          }
        };

        return new Promise(executePoll);
      };

      const apiService = async () => {
        return this.cctvService.getAvailableDownloads(moment(this.filters.dates.dtfrom).toISOString(), moment(this.filters.dates.dtto).toISOString(), this.filters.asset.id)
      };

      const validateItems = downloads => Array.isArray(downloads) ? downloads.every(e => e.mediaStatus !== 'pending') : false
      const pollInterval = 5000;
      const pollForDownloads = poll({
        fn: apiService,
        validate: validateItems,
        interval: pollInterval,
        maxAttempts: 100
      })
    }
  }

  loadCctvContent() {
    let alert = {
      message: '',
      button: {},
      mediaSettings: ''
    }
    this.content = {};
    this.selectedChannels = [];
    this.loading = true;
    this.cctvService.getAvailableMedia(moment(this.filters.dates.dtfrom).toISOString(), moment(this.filters.dates.dtto).toISOString(), this.filters.asset.deviceUUId, this.filters.asset.deviceId)
      .then(content => {
        if (content.id) {
          this.id = content.id;
          const poll = async ({ fn, validate, interval, maxAttempts }) => {
            let attempts = 0;

            const executePoll = async (resolve, reject) => {
              const result = await fn();
              attempts++;

              if (validate(result)) {
                if (result === 'idChanged') {
                  this.loading = true;
                } else if (result.channels && result.channels !== 'empty') {
                  this.contentMedia = result;
                  this.loadCustomRangeDates(result.items);
                  this.loadAlerts();
                  this.loadJourneys();
                  this.loading = false;
                } else {
                  this.errorMessage.message = 'No content';
                  this.contentMedia = {};
                  this.loadAlerts();
                  this.loadJourneys();
                  this.loading = false;
                }
                return resolve(result);
              } else if (maxAttempts && attempts === maxAttempts) {
                this.loading = false;
                alert.message = this.translations.getCap('unable_to_connect_to_device');
                alert.button.label = 'Try again';
                alert.button.action = 'data';
                alert.prompt = true;
                alert.mediaSettings = '';
                this.actions.globalMessage(alert);

              } else {
                if (!this.stopPoll && !this.unbindComponent) {
                  setTimeout(executePoll, interval, resolve, reject);
                }
              }
            };

            return new Promise(executePoll);
          };

          const apiService = async () => {
            if (this.filters.dates.dtfrom && this.filters.dates.dtto && this.filters.asset && this.filters.asset.id) {
              return this.id === content.id ? this.cctvService.getCctvData(content.id, this.filters.dates.dtfrom) : 'idChanged'
            }
          };

          const validateItems = c => c && c !== 204 && c.channels || c === 'idChanged'
          const pollInterval = 2000;
          const polFunc = poll({
            fn: apiService,
            validate: validateItems,
            interval: pollInterval,
            maxAttempts: 25
          })
        }
      })
      .catch(() => {
        alert.message = this.translations.getCap('unable_to_connect_to_device');
        alert.button.label = 'Try again';
        alert.button.action = 'data';
        alert.prompt = true;
        alert.mediaSettings = '';
        this.actions.globalMessage(alert);

        this.content = {};
        this.contentMedia = {};
        this.loading = false;
      });
  }

  loadAlerts() {
    this.loading = true;
    this.alertsService.getOccurrences(null, this.filters.asset.id, this.filters.dates.dtfrom, this.filters.dates.dtto).then(alerts => {
      this.contentMedia.alerts = alerts;
      this.loading = false;
    });
  }

  loadJourneys() {
    this.loading = true;
    this.telemetryService.getJourneys(this.filters.asset.id, this.filters.dates.dtfrom, this.filters.dates.dtto).then(trips => {
      this.contentMedia.journeys = trips;
      this.loading = false;
    });
  };

  openShareDialog(mediaId) {
    this.dialogService
      .open({
        viewModel: ShareDownloadDialog,
        model: {
          mediaId: mediaId
        }
      })
  }

  loadCustomRangeDates(dates) {
    let start = dates.map(d => moment(d.beginTime));
    let end = dates.map(d => moment(d.endTime));

    this.customDates.dtfrom = checkDates(moment.min(start), 'start', this.filters.dates.dtfrom, this.filters.dates.dtto);
    this.customDates.dtto = checkDates(moment.max(end), 'end', this.filters.dates.dtfrom, this.filters.dates.dtto);
    function checkDates(date, type, dtfrom, dtto) {
      if (type === 'start') {
        return new Date(date.startOf('hour'));
      } else {
        let m = 59 - date.minute();
        let s = 59 - date.second();
        m = m + date.minute();
        s = s + date.second();

        return new Date(date.startOf('hour').add(m, 'minutes').add(s, 'seconds'));
      }
    }
  };

  @computedFrom('selectedChannels')
  get availableChannels() {
    let availableChannels = this.selectedChannels.filter(channel => channel.selected)
    return (availableChannels.length) ? true : false;
  }

  startMedia() {
    if (!this.filters.dates.dtfrom || !this.selectedChannels.length || this.playing) return;
    try {
      this.selectedChannels.forEach(channel => {
        if (channel.selectedMedia && channel.el) {

          if (!this.paused && this.timelineCurrentDate && channel.camera && this.filters.asset.deviceUUId) {

            this.playingSettingsMedia = [];
            channel.selectedMedia.videoStartDate = this.timelineCurrentDate;

            channel.el = new JMuxer({
              node: 'video-js-' + channel.id,
              mode: 'video',
              flushingTime: 1000,
              fps: 23,
              onError: function () {
                if (/Safari/.test(navigator.userAgent) && /Apple Computer/.test(navigator.vendor)) {
                  channel.el.reset();
                }
              }
            });

            this.cctvService.getMediaElement(moment(channel.selectedMedia.videoStartDate).toISOString(), channel.camera, this.filters.asset.deviceUUId).then(stream => {
              if (stream.id) {

                this.playingSettingsMedia.push({
                  channel: channel.camera,
                  id: stream.id,
                  device: this.filters.asset.deviceUUId,
                  element: channel.el
                })

                const connection = new signalR.HubConnectionBuilder()
                  .withUrl(this.cctvService.getStreamVideoUrl(stream.id))
                  .withHubProtocol(new MessagePackHubProtocol())
                  .build();

                connection
                  .start()
                  .then(() => {
                    console.log("websocket: connected");

                    channel.connection = connection;
                  })
                  .catch(err => console.log("Error while establishing a connection.", err));

                connection.on('Streaming', (data) => {
                  channel.el.feed({
                    video: new Uint8Array(data)
                  });
                });
              }

            }).catch(e => {
              let alert = {
                message: '',
                button: {},
                mediaSettings: ''
              }

              if (e.data.request.status == 409) {
                this.playingSettingsMedia.push({
                  channel: channel.camera,
                  device: this.filters.asset.deviceUUId
                })

                alert.message = this.translations.getCap('channel_is_busy');
                alert.button.label = 'Override';
                alert.button.action = 'stream';
                alert.prompt = true;
                alert.mediaSettings = this.playingSettingsMedia;
                this.actions.globalMessage(alert);
              } else {
                alert.message = this.translations.getCap('service_unavailable');
                this.actions.globalMessage(alert);
              }
            })
          }

          channel.el.node.play().catch(
            (error) => {
              if (error && error.toString().toLowerCase().includes('the play() request was interrupted by a new load request') ||
                error && error.toString().toLowerCase().includes('the play() request was interrupted by a call to pause()') ||
                error && error.toString().toLowerCase().includes("the fetching process for the media resource was aborted by the user agent at the user's request.")) {
                // do nothing, we catch only this exception                   

              } else {
                throw error
              }
            });

          this.playing = true;
          this.paused = false;
        }
      });
    } catch {
      this.errorMessage = 'Loading media error, try again later.'
    }
  }

  pauseMedia(detach = false) {
    if (!this.filters.dates.dtfrom) return;
    try {

      this.selectedChannels.forEach( ( channel ) => {
        if ( channel.connection ) {
          channel.connection?.stop().then( () => {
            console.log( 'websocket: disconnected' );
          });
          channel.connection = null;
        }
      });

      if (this.playingSettingsMedia && this.playingSettingsMedia.length > 0) {
        this.playingSettingsMedia.forEach(m => {

          if ( m.element.node && m.element.node.paused ) {
            m.element.node.pause();
          }

          this.playing = false;
          this.paused = true;
          this.loadingMedia = false;

          if (detach) {
            this.paused = false;

            m.element.reset();
            m.element.node.removeAttribute('src');
            m.element.node.load();
            m.element.destroy();
            this.cctvService.stopLiveStreaming(m.id, m.device, m.channel).catch(e => {
              console.log('Error stop streaming', e)
            })
          }
        })
      }
    }
    catch (e) {
      throw e
    }
  }

  onTimeUpdate(channel) {
    if (!channel.el || !channel.selectedMedia) return;

    if (this.playing) {
      if (channel.id && this.videoTime !== Number(channel.el.node.currentTime.toFixed(0))) {
        this.videoTime = Number(channel.el.node.currentTime.toFixed(0));
        this.timelineCurrentDate = moment(channel.selectedMedia.videoStartDate).add(this.videoTime, 'seconds').toISOString();
      }
    }
  }

  unbind() {
    this.unbindComponent = true;
  }

}
